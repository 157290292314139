<template>
  <div class="container">
    暂未发布
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  min-height: 600px;
}
</style>
